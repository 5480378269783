
import { Component, Vue } from "vue-property-decorator";
import { URL } from "@/types";
import axios, { AxiosError } from "axios";

interface Result {
  experiment: number;
}

@Component({})
export default class ExptByProbe extends Vue {
  public error = "";
  public probeName = "";
  public experiments: Result[] = [];

  public search() {
    axios
      .get<Result[]>(`${URL}/expt_by_probe/${this.probeName}`)
      .then((resp) => (this.experiments = resp.data))
      .catch((resp: Error | AxiosError) => {
        if (axios.isAxiosError(resp)) {
          this.error = `${resp.message}: ${JSON.stringify(
            resp.response?.data
          )}`;
        } else {
          this.error = resp.message;
        }
      });
  }
}
