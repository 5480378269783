
import { Vue, Component } from "vue-property-decorator";

@Component({})
export default class Enjoying extends Vue {
  public item: string | null = null;
  public mounted() {
    this.item = localStorage.getItem("enjoying");
    if (this.item) {
      let x = JSON.parse(this.item);
      let now = Date.now();
      if (x > now) {
        localStorage.removeItem("enjoying");
        this.item = null;
      }
    }
    this.$bvModal.show("modal");
  }

  public ok() {
    let now = Date.now();
    now += 604800000;
    this.item = now.toString();
    console.log(this.item);
    localStorage.setItem("enjoying", this.item);
  }

  public cancel() {
    return null;
  }
}
