
import { Vue, Component, Watch } from "vue-property-decorator";
import axios from "axios";
import { URL, PaginationResponse } from "@/types";
import Chart from "chart.js/auto";

export interface CompoundsScreened {
  timestamp: string;
  year: number;
  week: number;
  targeted: number;
  untargeted: number;
  site_of_labelling: number;
}

@Component
export default class extends Vue {
  public error = "";
  public loading = false;
  public data: CompoundsScreened[] = [];

  public mounted() {
    this.loading = true;
    axios
      .get<CompoundsScreened[]>(`${URL}/reports/compounds_screened`)
      .then((resp) => {
        this.data = resp.data;
        this.loading = false;
        this.mk_chart();
      })
      .catch((resp) => {
        if (axios.isAxiosError(resp)) {
          this.error = `Error fetching QC metrics: ${resp.message}`;
        } else {
          this.error = `Error fetching QC metrics: ${resp}`;
        }
      });

  }

  public mk_chart() {
    const ctx = this.$refs.ctx as HTMLCanvasElement;
    if (!ctx) {
      return;
    }

    // https://stackoverflow.com/questions/40056555/destroy-chart-js-bar-graph-to-redraw-other-graph-in-same-canvas
    let chartStatus = Chart.getChart(ctx);
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }

    let data = this.data;
    let max_targeted = 0;
    let max_untargeted = 0;
    let max_sol = 0;
    for (let entry of data) {
      entry.untargeted = Math.max(entry.untargeted, max_untargeted);
      max_untargeted = entry.untargeted;

      entry.targeted = Math.max(entry.targeted, max_targeted);
      max_targeted = entry.targeted;

      entry.site_of_labelling = Math.max(entry.site_of_labelling, max_sol);
      max_sol = entry.site_of_labelling;
    }

    const _ = new Chart(ctx, {
      type: "line",
      data: {
        labels: data.map((x) => new Date(x.timestamp).toDateString()),
        datasets: [
          {
            label: "Global",
            data: data.map((x) => x.untargeted),
            backgroundColor: "#0000FF40",
            borderColor: "blue",
            fill: true,
          },
          {
            label: "Targeted",
            data: data.map((x) => x.targeted),
            backgroundColor: "#FF000040",
            borderColor: "red",
            fill: true,
          },
          {
            label: "SoL",
            data: data.map((x) => x.site_of_labelling),
            backgroundColor: "#00FF0040",
            borderColor: "green",
            fill: true,
          },
        ],
      },
    });
  }
}
