
import { Prop, Component, Vue } from "vue-property-decorator";
import axios, { AxiosError } from "axios";
import { Experiment, LAMBDA_API, Layout, URL } from "@/types";
import { mapGetters } from "vuex";
import { SelectItem } from "@/store";

@Component({
  computed: {
    ...mapGetters(["get_cells"]),
  },
})
export default class Upload extends Vue {
  @Prop() readonly experiment!: Experiment;
  public get_cells!: SelectItem[];

  public error = "";
  public filePath: File | null = null;
  public options = [
    "targeted",
    "global",
    "dose_response",
    "site_of_labelling",
    "qc",
    "yeast_qc",
    "prm_cal_curve",
    "prm",
  ];
  public type = "targeted";
  public fraction = 1;

  public cal_curve_ref: string | null = null;

  public async upload() {
    if (!this.filePath) {
      this.error = "No file selected!";
      return;
    }
    let data = this.experiment.layouts
      .map((x) => ({
        ...x,
        cell: this.get_cells.find((y) => y.value == x.cell)?.text,
      }));
    for (const lay of this.experiment.layouts) {
      if (!this.get_cells.find((y) => y.value == lay.cell)) {
        this.$bvToast.toast(
          "Error - missing cell line info - try reloading page!",
          {
            title: "Error",
            autoHideDelay: 5000,
            appendToast: true,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-top-center",
          }
        );
        return;
      }
    }
    try {
      let apiurl = 
        this.cal_curve_ref === null 
          ? `${URL}/upload_to_qc/${this.experiment.id}?type=${this.type}&fraction=${this.fraction}`
          : `${URL}/upload_to_qc/${this.experiment.id}?type=${this.type}&fraction=${this.fraction}&cal_curve=${this.cal_curve_ref}`;

      let s3url = await axios.post<{url: string}>(apiurl, data);
      await axios.put(s3url.data.url, this.filePath, {
        headers: { "Content-Type": "" },
      });

      this.$bvToast.toast(
        "Upload succeeded - check email to see processing results",
        {
          title: "Success",
          autoHideDelay: 5000,
          appendToast: true,
          variant: "success",
          solid: true,
          toaster: "b-toaster-top-center",
        }
      );
    } catch (resp: any) {
      if (axios.isAxiosError(resp)) {
        this.error = `${resp.message}: ${JSON.stringify(resp.response?.data)}`;
      } else {
        this.error = resp.message;
      }
    }
  }
}
