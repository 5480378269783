// Okta integration
// https://github.com/okta/okta-vue/blob/master/src/okta-vue.ts
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import ExperimentView from "@/views/Experiment.vue";
import ExperimentList from "@/views/ExperimentList.vue";
import ExptByProbe from "@/views/ExptByProbe.vue";
import LoginCallback from "@/components/LoginCallback.vue";
import Queue from "@/views/Queue.vue";
import Signout from "@/components/Signout.vue";
import { oktaAuth } from "@/main";
import { AuthState } from "@okta/okta-auth-js";
import MethodVue from "@/components/Method.vue";
import MetricsVue from "@/views/Metrics.vue";
import FlaggedCompoundsVue from "@/views/FlaggedCompounds.vue";
import ViewMultipleSummedIntensityVue from "@/components/ViewMultipleSummedIntensity.vue";
import BulkUpload from "@/views/BulkUpload.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: ExperimentList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/bulk",
    name: "BulkUpload",
    component: BulkUpload,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login/callback",
    name: "LoginCallback",
    component: LoginCallback,
  },
  {
    path: "/signout",
    name: "Signout",
    component: Signout,
  },
  {
    path: "/experiment/:pk?",
    name: "ExperimentView",
    component: ExperimentView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/queue",
    name: "InstrumentQueue",
    component: Queue,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/exptbyprobe",
    name: "ExptByProbe",
    component: ExptByProbe,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/metrics",
    name: "Metrics",
    component: MetricsVue,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/method",
    name: "Method",
    component: MethodVue,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/flagged",
    name: "Flagged",
    component: FlaggedCompoundsVue,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/intensities",
    name: "Intensities",
    component: ViewMultipleSummedIntensityVue,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

let _originalUrl = "";

const guardRoute = async (authState: AuthState | null) => {
  if (authState && !authState.isAuthenticated) {
    oktaAuth.setOriginalUri(_originalUrl);
    await oktaAuth.signInWithRedirect();
  }
};

router.beforeEach(async (to, from, next) => {
  oktaAuth.authStateManager.unsubscribe(guardRoute);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    console.log("needs auth");
    oktaAuth.authStateManager.subscribe(guardRoute);
    const isAuth = await oktaAuth.isAuthenticated();
    // const authState = await oktaAuth.authStateManager.getAuthState();
    // if (authState) {
    //   console.log(authState.idToken?.claims.email);
    // }
    // console.log(isAuth);
    if (!isAuth) {
      _originalUrl = to.fullPath;
      const authState = await oktaAuth.authStateManager.getAuthState();
      console.log(authState);
      await guardRoute(authState);
      return false;
    }
    next();
  } else {
    next();
  }
});

export default router;
