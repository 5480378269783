
import { Vue, Component } from "vue-property-decorator";

export interface Parameter {
  type: "parameter";
  name: string;
  default: string;
  value: string;
}

export interface Literal {
  type: "literal";
  literal: string;
}

export interface Break {
  type: "break";
}

export type Node = Parameter | Literal | Break;
export type Steps = (Parameter | Literal)[];

@Component
export default class extends Vue {
  // public nodes: Node[] = [];
  public nodes: Node[] = [
    { type: "literal", literal: `Plate` },
    {
      type: "parameter",
      name: "cell_count",
      default: "1 million",
      value: "1 million",
    },
    {
      type: "literal",
      literal: `in 200µl spent media in 96 well plate containing 2ul probe`,
    },
    { type: "break" },
    {
      type: "literal",
      literal: `Probe is a final of 20µM final (2µl of 2mM stock) and is incubated for 30 minutes at 37C`,
    },
    { type: "break" },
    {
      type: "literal",
      literal: `Remove tissue culture dish lid and irradiate with`,
    },
    {
      type: "parameter",
      name: "crosslink_energy",
      default: "5000 mJ/cm2",
      value: "5000 mJ/cm2",
    },
    {
      type: "literal",
      literal: `under 365-nM UV light in Stratagene UV Stratalinker 1800 at 4C`,
    },
    { type: "break" },
    {
      type: "literal",
      literal: `Spin down cells at 1000xg for 2 min and aspirate media`,
    },
    { type: "break" },
    {
      type: "literal",
      literal: `Wash cells with 3 X PBS (200µL), spin down at 1000xg for 2 min, aspirate PBS, freeze -80`,
    },
    { type: "break" },
    {
      type: "literal",
      literal: `Lyse cells with 120µl 0.1% SDS/1:1000 benzonase (<a href="https://www.fishersci.com/shop/products/benzonase-nuclease-3/712063">FisherSci 71-206-3</a>)`,
    },
    { type: "break" },
    { type: "literal", literal: `Mix lysed cells by pipetting` },
    { type: "break" },
    {
      type: "literal",
      literal: `Determine protein concentration by DC assay (BIORAD, Reagent A: <a href="https://www.bio-rad.com/en-us/sku/5000113-protein-assay-reagent?ID=5000113">CAT#5000113</a>, Reagent B: 
            <a href="https://www.bio-rad.com/en-us/sku/5000114-protein-assay-reagent-b?ID=5000114">CAT#50000114</a>)
                <ul>
                <li>Make standard BSA curve in duplicate of 1-20 µgs final</li>
                <li>Add 1 µl of proteome sample in duplicate</li>
                <li>Add 20 µls of BioRad DC Protein Reagent A, mix samples</li>
                <li>Add 160 µls of BioRad DC Protein Reagent B</li>
                <li>Read on Lowry setting microplate at 750 nM</li>
                </ul>`,
    },
    { type: "break" },
    { type: "literal", literal: `Use` },
    { type: "parameter", name: "tamra_amount", value: "25ug", default: "25ug" },
    {
      type: "literal",
      literal: `in 20uL for TAMRA clicks (described below) and`,
    },
    {
      type: "parameter",
      name: "biotin_clicks",
      value: "240ug",
      default: "240ug",
    },
    {
      type: "literal",
      literal: `in 100uL for biotin clicks (described below) by transferring into 0.5mL 96-well block (biotin) or PCR (TAMRA) plate`,
    },
    {
      type: "literal",
      literal: `<p><b>For TAMRA SDS-PAGE</b>, add 2.4µl of click stock per sample, vortex, and incubate at room temperature for 1h<p>
      <p>Stop TAMRA click by adding 4X loading dye (NUPAGE) (CAT# NP0008 with DTT) to a final concentration of 1X and run 12.5µls (~12.5ugs on gel) and scan</p>
      <hr>
      <p><b>FOR TAMRA Florescent Intensity</b> (can be run in parallel with Biotin clicks:</p>
      <ol>
      <li>Into a polypropylene 96-well, V-Bottom plate, add 30ul Lysate to well (@25ug/sample)</li>
      <li>Add 3.6ul 5-TAMRA Click master mix</li>
      <li>Incubate @ RT for 1hr</li>
      <li>Precipitate with 120ul 4:1 ColdMeOH:CHCl3</li>
      <li>Spin at 2700xg @ 4C for 10min</li>
      <li>Decant supernatant, and light tap residual supe on Kimwipe</li>
      <li>Add 150ul cold MeOH</li>
      <li>Spin at 2700xg at 4C for 10min</li>
      <li>Decant supernatant, and lightly tap out remaining MeOH</li>
      <li>Dry Plate at 65C for 10min</li>
      <li>Add 100ul 8M Urea/0.5% SDS in PBS to each well</li>
      <li>Incubate at 65C, shaking for 30min</li>
      <li>Transfer to black-walled, flat-bottom 96-well plate</li>
      <li>On Clariostar, measure Fluorescent intensity  (Program: FI TAMRA) Ex: 530-40 / Em: 590-20</li>
      </ol>`,
    },
    { type: "break" },
    {
      type: "literal",
      literal: `For biotin click, add 11µl per sample, RT shaking 700rpm 1hr`,
    },
    { type: "break" },
    {
      type: "literal",
      literal: `For biotin clicks, precipitate proteins by adding 430 µL of 4:1 MeOH:CHCl3 (530µl total)`,
    },
    { type: "break" },
    {
      type: "literal",
      literal: `Centrifuge precipitated proteins at 2700xg for 10 min at 4C`,
    },
    { type: "break" },
    { type: "literal", literal: `Decant supernatant (do this step gently)` },
    { type: "break" },
    {
      type: "literal",
      literal: `Add 500µl MeOH and spin again at 2700xg for 10min at 4C`,
    },
    { type: "break" },
    { type: "literal", literal: `Decant and allow pellet to dry for 5 min` },
    { type: "break" },
    {
      type: "literal",
      literal: `Add 100µl 8M Urea, 0.5% SDS with 10mM DTT (15min @65C then, 15min Vigorous shaking)`,
    },
    { type: "break" },
    { type: "literal", literal: `IA alkylation at 20mM for 30min at RT` },
    { type: "break" },
    {
      type: "literal",
      literal: `Add 60µl Streptavidin Agarose (Fisher Sci PI20353) in 200ul PBS (washed 1x in PBS) (300ul final volume)`,
    },
    { type: "break" },
    { type: "literal", literal: `Plate shaker RT for 1hr (800 rpm)` },
    { type: "break" },
    {
      type: "literal",
      literal: `Transfer samples to OF1100 filter plate (place filter plate on top a microplate reservoir during washes)`,
    },
    { type: "break" },
    { type: "literal", literal: `Wash 2x with 500µl 0.2% SDS` },
    { type: "break" },
    { type: "literal", literal: `Wash with 2x 500µl dPBS` },
    { type: "break" },
    { type: "literal", literal: `Wash with 2x 500µl HPLC grade water` },
    { type: "break" },
    {
      type: "literal",
      literal: `Spin down at 500xg 1min to ensure all volume is out of plate`,
    },
    { type: "break" },
    {
      type: "literal",
      literal: `Resuspend beads in 30µl trypsin 100 mM EPPS pH 8.5 (0.2ug trypsin/sample)`,
    },
    { type: "break" },
    {
      type: "literal",
      literal: `Seal plate and incubate at RT (benchtop) overnight`,
    },
    { type: "break" },
    {
      type: "literal",
      literal: `Transfer all samples to 0.5ml deepwell plate by centrifugation (directly into TMT containing plate if TMT labeling) at 1000xg for 1min`,
    },
    { type: "break" },
    {
      type: "literal",
      literal: `Rinse wash well with 30ul 5% ACN, 100mM Hepes 8.5, spin @ 1000xg for 1min`,
    },
    { type: "break" },
  ];

  /// Ensure that two literals do not occur next to each other - probably not a big
  /// deal though :)
  public condense_nodes(nodes: Node[]): Node[] {
    switch (nodes.length) {
      case 0:
        return nodes;
      case 1:
        return nodes;
      default: {
        let out = [];
        let last = nodes[0];
        for (let node of nodes.slice(1)) {
          if (last.type == "literal" && node.type == "literal") {
            last.literal += " " + node.literal;
          } else {
            out.push(last);
            last = node;
          }
        }
        out.push(last);
        return out;
      }
    }
  }

  public mk_steps(nodes: Node[]): Steps[] {
    let steps = [];
    let acc: Steps = [];
    for (let node of nodes) {
      if (node.type == "break") {
        steps.push(acc);
        acc = [];
      } else {
        acc.push(node);
      }
    }
    if (acc.length > 0) {
      steps.push(acc);
    }
    return steps;
  }

  get steps(): Steps[] {
    return this.mk_steps(this.condense_nodes(this.nodes));
  }

  get parameters(): Parameter[] {
    let out = [];
    for (let node of this.nodes) {
      if (node.type == "parameter") {
        out.push(node);
      }
    }
    return out;
  }
}
