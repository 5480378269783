
import { Vue, Component, Watch } from "vue-property-decorator";
import axios from "axios";
import { URL, PaginationResponse } from "@/types";
import Chart from "chart.js/auto";

export interface QcMetrics {
  experiment_id: number;
  instrument: string;
  timestamp: string;
  // year: number;
  // month: number;
  // day: number;
  quantified_proteins: number;
  quantified_psms: number;
  psms: number;
  avg_cv: number;
  avg_interference: number;
}

@Component
export default class extends Vue {
  public error = "";
  public loading = false;
  public data: QcMetrics[] = [];

  public options: string[] = ["All"];
  public instrument = "All";

  public metric = "Interference";

  public mounted() {
    this.loading = true;
    axios
      .get<QcMetrics[]>(`${URL}/reports/qc_metrics2`)
      .then((resp) => {
        this.data = resp.data;
        this.options.push(...new Set(resp.data.map((x) => x.instrument)));
        this.loading = false;

        this.mk_chart();
      })
      .catch((resp) => {
        if (axios.isAxiosError(resp)) {
          this.error = `Error fetching QC metrics: ${resp.message}`;
        } else {
          this.error = `Error fetching QC metrics: ${resp}`;
        }
      });
  }

  get qc_data() {
    if (this.instrument != "All") {
      return this.data.filter((x) => x.instrument == this.instrument);
    } else {
      return this.data;
    }
  }

  @Watch("instrument")
  @Watch("metric")
  public mk_chart() {
    const ctx = this.$refs.ctx as HTMLCanvasElement;
    if (!ctx) {
      return;
    }

    let chartStatus = Chart.getChart(ctx);
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }

    // const data = this.qc_data.reverse();
    const data = this.qc_data;
    if (this.metric == "Interference") {
      const _ = new Chart(ctx, {
        type: "line",
        data: {
          // labels: data.map((x) => `BTX${x.experiment_id}`),
          labels: data.map((x) => new Date(x.timestamp).toLocaleDateString()),
          datasets: [
            {
              label: "Avg TKO Interference-free index",
              data: data.map((x) => x.avg_interference),
              backgroundColor: "red",
              borderColor: "red",
            },

            {
              label: "Avg CV",
              data: data.map((x) => x.avg_cv),
              backgroundColor: "blue",
              borderColor: "blue",
            },
          ],
        },
      });
    } else {
      const _ = new Chart(ctx, {
        type: "line",
        data: {
          // labels: data.map((x) => `BTX${x.experiment_id}`),
          labels: data.map((x) => new Date(x.timestamp).toLocaleDateString()),
          datasets: [
            {
              label: "Quantified PSMS",
              data: data.map((x) => x.quantified_psms),
              backgroundColor: "red",
              borderColor: "red",
            },
          ],
        },
      });
    }
  }
}
