
import { Vue, Component, Watch } from "vue-property-decorator";
import axios from "axios";
import { URL, PaginationResponse } from "@/types";
import Chart from "chart.js/auto";

export interface QcMetrics {
  id: number;
  experiment: number;
  instrument_name: string;
  timestamp: string;
  run_type: string;
  run_fraction: number;
  psm_count: number;
  quantified_psms: number;
  quantified_proteins: number;
}

// const knockouts = ['MET6', 'HIS4', 'URA2']
// const controls = ['ENO2', 'PGK1', 'RPL10']

type GraphMetric = "psm_count" | "quantified_psms" | "quantified_proteins";
@Component
export default class extends Vue {
  public error = "";
  public loading = false;
  public data: QcMetrics[] = [];

  public metric: GraphMetric = "psm_count";
  public options = ["psm_count", "quantified_psms", "quantified_proteins"];

  public mounted() {
    this.loading = true;
    axios
      .get<PaginationResponse<QcMetrics>>(`${URL}/experiment_metrics?limit=100`)
      .then((resp) => {
        this.data = resp.data.results;
        this.loading = false;
        this.mk_chart();
      })
      .catch((resp) => {
        if (axios.isAxiosError(resp)) {
          this.error = `Error fetching QC metrics: ${resp.message}`;
        } else {
          this.error = `Error fetching QC metrics: ${resp}`;
        }
      });
  }

  @Watch("metric")
  public mk_chart() {
    const ctx = this.$refs.ctx as HTMLCanvasElement;
    if (!ctx) {
      return;
    }

    // https://stackoverflow.com/questions/40056555/destroy-chart-js-bar-graph-to-redraw-other-graph-in-same-canvas
    let chartStatus = Chart.getChart(ctx);
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }

    let data = this.data.filter((x) => x.run_type == "global").reverse();
    const _ = new Chart(ctx, {
      type: "line",
      data: {
        labels: data.map((x) => `BTX${x.experiment}.${x.run_fraction}`),
        datasets: [
          {
            label: this.metric,
            data: data.map((x) => x[this.metric]),
            backgroundColor: "blue",
            borderColor: "blue",
            fill: true,
          },
        ],
      },
    });
  }
}
