
import { Prop, Component, Vue } from "vue-property-decorator";
import axios, { AxiosError } from "axios";
import { URL, LAMBDA_API, FileResponse } from "@/types";

@Component({})
export default class Files extends Vue {
  @Prop() readonly pk!: number;

  public files: FileResponse[] = [];
  public error = "";

  public created() {
    axios
      .get<FileResponse[]>(`${LAMBDA_API}?key=${this.pk}`)
      .then((resp) => (this.files = resp.data))
      .catch((resp: any) => {
        if (axios.isAxiosError(resp)) {
          this.error = `${resp.message}: ${JSON.stringify(
            resp.response?.data
          )}`;
        } else {
          this.error = resp.message;
        }
      });
  }

  public extract(str: string): string {
    let s = str.split("#");
    if (s.length >= 4) {
      return s[3] + (s.length >= 6 ? `#${s[5]}` : "");
    } else {
      return str;
    }
  }

  get items() {
    return this.files.map((val) => {
      return {
        ...val,
        pk: this.extract(val.pk),
      };
    });
  }
}
