
import { Vue, Component } from "vue-property-decorator";
import { oktaAuth } from "@/main";

@Component
export default class extends Vue {
  public error = "";

  public async mounted() {
    try {
      await oktaAuth.handleLoginRedirect();
    } catch (e) {
      console.log(e);
    }
  }
}
