
import {
  Instrument,
  Experiment,
  Folder,
  URL,
  STATUSES,
  status,
  PaginationResponse,
} from "@/types";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
import axios from "axios";
import Enjoying from "@/components/Enjoying.vue";
import { SelectItem } from "@/store";
import { oktaAuth } from "@/main";

interface ExperimentLite {
  id: number;
  comment: string;
  folder: number;
  folder_name: string;
  instrument: number;
  instrument_name: string;
  created: string;
  last_audit: {
    status: status;
  };
  uploaded_to_production: boolean;
  dummy: string | null;
}

@Component({
  computed: {
    ...mapGetters(["get_instruments", "get_projects"]),
  },
  components: {
    Enjoying,
  },
})
export default class ExperimentList extends Vue {
  public get_instruments!: SelectItem[];
  public get_projects!: SelectItem[];
  public totalExperiments = 0;
  public experiments: ExperimentLite[] = [];
  public statuses = STATUSES;
  public partialSearch = "";

  public filter = {
    status: null,
    instrument: null,
    project: null,
  };

  public pagination = {
    current: 1,
    per: 100,
  };

  get filter_experiments() {
    return this.experiments.filter((ex) => {
      if (this.filter.instrument && this.filter.instrument != ex.instrument) {
        return false;
      } else if (
        this.filter.status &&
        this.filter.status != ex.last_audit.status
      ) {
        return false;
      } else if (this.filter.project && this.filter.project != ex.folder) {
        return false;
      }

      return true;
    });
  }

  public fields = [
    { key: "flagged", label: "", sortable: false },
    { key: "id", label: "Identifier", sortable: true },
    { key: "comment", label: "comment", sortable: true },
    { key: "folder_name", label: "Project", sortable: true },
    { key: "created", label: "Date created", sortable: true },
    { key: "last_audit", label: "Status", sortable: true },
    // { key: "instrument_name", label: "Assigned instrument" },
    { key: "instrument", label: "Instrument", sortable: true },
    { key: "dummy", label: "Update", sortable: false },
    { key: "uploaded_to_production", label: "Portal", }
  ];

  public loading = false;
  public error = "";
  public download_experiments(limit: number, offset: number) {
    this.loading = true;
    axios
      .get<PaginationResponse<ExperimentLite>>(
        `${URL}/lite-experiment/?limit=${limit}&offset=${offset}`
      )
      .then((resp) => {
        this.totalExperiments = resp.data.count;
        this.experiments = resp.data.results;
        this.loading = false;
      })
      .catch((resp) => {
        if (axios.isAxiosError(resp)) {
          this.error = `Error occured while downloading experiments: ${resp.message}`;
        } else {
          this.error = `Error occured while downloading experiments`;
        }
      });
  }

  public mounted() {
    this.download_experiments(this.pagination.per, 0);
  }

  @Watch("pagination.current")
  public fetchData() {
    this.download_experiments(
      this.pagination.per,
      this.pagination.per * (this.pagination.current - 1)
    );
  }

  @Watch("pagination.per")
  public fetchData2() {
    this.download_experiments(
      this.pagination.per,
      this.pagination.per * (this.pagination.current - 1)
    );
  }

  @Watch("partialSearch")
  public fetchPartialSearch() {
    // this.loading = true;
    if (this.partialSearch.length == 0) {
      return this.fetchData();
    }
    this.loading = true;
    axios
      .get<ExperimentLite[]>(`${URL}/experiment/search/${this.partialSearch}`)
      .then((resp) => {
        this.totalExperiments = resp.data.length;
        // this.totalExperiments = resp.data.count;
        this.experiments = resp.data;
        this.loading = false;
        // console.log(resp.data);
      })
      .catch((resp) => {
        if (axios.isAxiosError(resp)) {
          this.error = `Error occured while downloading experiments: ${resp.message}`;
        } else {
          this.error = `Error occured while downloading experiments`;
        }
      });
  }

  public async update_experiment(row: ExperimentLite) {
    this.loading = true;
    try {
      const user = await oktaAuth.getUser();
      const resp = await axios.get<Experiment>(`${URL}/experiment/${row.id}`);
      let experiment = resp.data;
      experiment.instrument = row.instrument;
      experiment.last_audit.status = row.last_audit.status;
      experiment.last_audit.comment = "update status/instrument";
      experiment.last_audit.user = user.email ? user.email : "homepage";
      experiment.last_audit.timestamp = undefined;
      console.log(experiment);

      const resp2 = await axios.put(`${URL}/metadata/${row.id}`, experiment);
      if (resp2.status !== 200) {
        throw resp2;
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        this.error = `Error occured while updating experiment BTX${row.id}: ${err.message}`;
      } else {
        this.error = `Error occured while updating experiment BTX${row.id}`;
      }
    }
    this.loading = false;
  }
}
