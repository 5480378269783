import Vue from "vue";
import Vuex from "vuex";
import { Cell, Folder, Instrument } from "@/types";

Vue.use(Vuex);

const SET_CELLS = "CELLS";
const SET_INSTRUMENTS = "INSTRUMENTS";
const SET_PROJECTS = "PROJECTS";
const SET_DM_TOKEN = "DOTMATICS";

export interface Dotmatics {
  isid: string;
  token: string;
}

export interface SelectItem {
  value: number;
  text: string;
}
export default new Vuex.Store({
  state: {
    cells: [] as SelectItem[],
    instruments: [] as SelectItem[],
    projects: [] as SelectItem[],
  },
  mutations: {
    [SET_CELLS](state, cells: Cell[]) {
      state.cells = cells.map((x) => ({ value: x.id, text: x.name }));
    },
    [SET_INSTRUMENTS](state, instr: Instrument[]) {
      state.instruments = instr.map((x) => ({ value: x.id, text: x.name }));
    },
    [SET_PROJECTS](state, folders: Folder[]) {
      state.projects = folders.map((x) => ({ value: x.id, text: x.name }));
    },
  },
  getters: {
    get_cells(state) {
      return state.cells;
    },
    get_instruments(state) {
      return state.instruments;
    },
    get_projects(state) {
      return state.projects;
    },
  },
  actions: {
    load_cells({ commit }, cells: Cell[]) {
      commit(SET_CELLS, cells);
    },
    load_instruments({ commit }, instruments: Instrument[]) {
      commit(SET_INSTRUMENTS, instruments);
    },
    load_projects({ commit }, instruments: Folder[]) {
      commit(SET_PROJECTS, instruments);
    },
  },
  modules: {},
});
