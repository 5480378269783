
import { PropSync, Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { Layout, SI_UNITS } from "@/types";
import { SelectItem } from "@/store";

@Component({
  computed: {
    ...mapGetters(["get_cells", "get_instruments", "get_projects"]),
  },
})
export default class LayoutTable extends Vue {
  @PropSync("layout") layouts!: Layout[];
  public get_cells!: SelectItem[];
  public SI_UNITS = SI_UNITS;

  public fields = [
    "channel",
    "probe",
    "probe_conc",
    "probe_units",
    "competitor",
    "competitor_conc",
    "competitor_units",
    "timepoint",
    "cell",
    "enzyme",
    "ignore",
    { key: "index", label: "X" },
  ];

  public filldown(label: keyof Layout, index: number) {
    if (this.layouts.length > index) {
      //@ts-expect-error - given that `label` is the same for both, this isn't a type error
      this.layouts[index + 1][label] = this.layouts[index][label];
    }
  }

  public delete_layout(index: number) {
    this.layouts.splice(index, 1);
  }

  public add_layout() {
    if (this.layouts.length > 0) {
      let lay = {
        ...this.layouts[this.layouts.length - 1],
      };
      lay.channel = lay.channel + 1;
      this.layouts.push(lay);
    } else {
      this.layouts.push({
        probe: "",
        probe_conc: 0,
        probe_units: "uM",
        competitor: null,
        competitor_conc: null,
        competitor_units: null,
        cell: 1,
        timepoint: 0,
        channel: 1,
        enzyme: "KR",
        ignore: false,
      });
    }
  }
}
