
import { Instrument, Folder, URL } from "@/types";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { mapGetters } from "vuex";
import axios from "axios";
import { SelectItem } from "@/store";

export interface QueueApi {
  experiment_id: number;
  timestamp: string;
  waiting: string;
  user: string;
  folder: number;
}

@Component({
  computed: {
    ...mapGetters(["get_instruments", "get_projects"]),
  },
})
export default class Queue extends Vue {
  public get_instruments!: SelectItem[];
  public get_projects!: SelectItem[];
  public queue: QueueApi[][] = [];
  public sortBys: string[] = [];

  public fields = [
    { key: "experiment_id", label: "Identifier" },
    { key: "folder", label: "Project", sortable: true },
    // { key: "timestamp", label: "Date in queue", sortable: true },
    // { key: "waiting", label: "Time spent in queue", sortable: true },
    { key: "user", label: "User", sortable: true },
  ];

  public async get_experiments(id: number): Promise<QueueApi[]> {
    try {
      let ex = await axios.get<QueueApi[]>(URL + `/queue/${id}`);
      return ex.data;
    } catch (err) {
      window.alert(err);
      return [];
    }
  }

  public async mounted() {
    for (let instr of this.get_instruments) {
      let res = await this.get_experiments(instr.value);
      this.queue.push(res);
      this.sortBys.push("waiting");
    }
    console.log(this.queue);
  }

  public items(id: number) {
    if (this.queue.length > id) {
      return this.queue[id].map((item) => {
        const res = this.get_projects.find((x) => x.value == item.folder);
        let folder = "unknown";
        if (res) {
          folder = res.text;
        }
        return { ...item, folder: folder };
      });
    }
    return [];
  }

  public image(id: number) {
    switch (id) {
      case 1:
        return "../assets/meatlug.png";
      case 2:
        return "../assets/toothless.png";
      default:
        return "";
    }
  }
}
