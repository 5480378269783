
import { oktaAuth } from "@/main";
import { SelectItem, Dotmatics } from "@/store";
import {
  Cell,
  Experiment,
  FileResponse,
  LAMBDA_API,
  Layout,
  si_unit,
  SI_UNITS,
  URL,
} from "@/types";
import axios from "axios";
import { Vue, Component, Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";

type RunType = "targeted" | "global" | "siteoflabeling";

interface RunId {
  experiment_id: number;
  run_type: RunType;
  fraction: number;
}

type ChannelType = "sample" | "standard";

interface Treatment {
  identifier: string;
  smiles: string;
  concentration: number;
  units: si_unit;
}

interface Tmt {
  channel_idx: number;
  channel_type: ChannelType;
  cell: string;
  duration: number;
  probe: Treatment;
  competitor: Treatment | null;
}

interface UploadRequest {
  experiment: { type: RunType; fraction: number };
  portal: string;
  comment: string | null;
}

interface AlreadyUploaded {
  file: RunId;
  portal: string;
  portal_url: string;
}

interface PortalRunInfo {
  comment: string | null;
  channels: Tmt[];
}

@Component({
  computed: {
    ...mapGetters(["get_cells", "get_dm_token"]),
  },
})
export default class extends Vue {
  public get_cells!: SelectItem[];
  public get_dm_token!: Dotmatics | null;

  @Prop() experiment!: Experiment;
  public portals = [
    { value: "api.testing.diazirine.com", text: "Testing environment" },
    { value: "api.belharra.diazirine.com", text: "Production environment" },
  ];

  public already_fields = [
    { key: "portal", label: "Portal" },
    { key: "file", label: "File" },
    { key: "portal_url", label: "View" },
  ];

  public selected_portal = this.portals[0].value;
  public comment = "";
  public token = "";
  public error = "";
  public success = false;
  public pending = false;
  public files: FileResponse[] = [];
  public selected_run: FileResponse | null = null;
  public already: AlreadyUploaded[] = [];

  public mounted() {
    this.comment = this.experiment.comment;
    this.getfiles();
  }

  public getfiles() {
    axios
      .get<FileResponse[]>(`${LAMBDA_API}?key=${this.experiment.id}`)
      .then((resp) => {
        this.files = resp.data;
      })
      .then(async (resp) => (this.already = await this.already_published()))
      .catch((resp: any) => {
        if (axios.isAxiosError(resp)) {
          this.error = `${resp.message}: ${JSON.stringify(
            resp.response?.data
          )}`;
        } else {
          this.error = resp.message;
        }
      });
  }

  // Get cartesian product of files & portals that have been uploaded
  public async already_published(): Promise<AlreadyUploaded[]> {
    const token = oktaAuth.getAccessToken();
    if (!token) {
      return [];
    }

    let already = [];
    for (let file of this.files) {
      let runid = this.deserializeRunId(file.pk);
      if (runid.run_type == 'targeted') {
        continue;
      }
      for (let portal of this.portals) {
        for (let run_type of ["global", "competition", "dose_response"]) {
          const url = `https://${portal.value}/metadata/${runid.experiment_id}/${run_type}/${runid.fraction}`;
          let resp = await axios.get<PortalRunInfo>(url, {
            headers: { Authorization: `Bearer ${token}` },
          });

          if (resp.data && resp.data.channels.length > 0) {
            already.push({
              portal_url: portal.value,
              portal: portal.text,
              file: {...runid, run_type: run_type as RunType},
              // file: {
              //   ...runid,
              //   run_type: run_type
              // }
            });
          }
        }
      }
    }
    return already;
  }

  // Delete an extant file from a data portal
  public async delete_from(data: AlreadyUploaded) {
    const token = oktaAuth.getAccessToken();
    if (!token) {
      return [];
    }
    const url = `https://${data.portal_url}/metadata/${data.file.experiment_id}/${data.file.run_type}/${data.file.fraction}`;
    let resp = await axios.delete(url, {
      headers: { Authorization: `Bearer ${token}` },
    }).catch(() => {console.log("ERROR")});
    
    if (data.portal_url == "api.belharra.diazirine.com") {
      this.experiment.uploaded_to_production = false;
      await axios.put<Experiment>(`${URL}/metadata/${this.experiment.id}`, this.experiment);
    }


    this.already = this.already.filter((x) => x != data);
    this.$bvToast.toast("Experiment removed from portal!", {
      title: "Success",
      autoHideDelay: 5000,
      appendToast: true,
      variant: "success",
      solid: true,
      toaster: "b-toaster-top-center",
    });
  }

  // Publish a file to a data portal
  public async publish() {
    if (this.selected_run === null) {
      this.error = "No run selected!";
      return;
    }
    let run_id = this.deserializeRunId(this.selected_run.pk);

    let experiment: UploadRequest = {
      experiment: { type: run_id.run_type, fraction: run_id.fraction },
      portal: this.selected_portal,
      comment: this.comment,
    };

    this.pending = true;
    axios
      .post<string>(`${URL}/publish/${this.experiment.id}`, experiment)
      .then((resp) => {
        this.pending = false;
        this.success = true;
      })
      .catch((resp: any) => {
        this.pending = false;
        if (axios.isAxiosError(resp)) {
          let err = JSON.stringify(resp.response?.data);
          console.log(err);
          if (err == '"Compound matching query does not exist."') {
            err =
              "compound in TMT layout does not exist in database - is it a BTX compound?";
          }
          this.error = `${resp.message}: ${err}`;
        } else {
          this.error = resp.message;
        }
      });
  }

  public deserializeRunId(s: string): RunId {
    let xs = s.split("#");
    let id = Number(xs[1]);
    let type = xs[3] as RunType;
    let fraction = 1;
    if (xs.length == 6) {
      fraction = Number(xs[5]);
    }
    return { experiment_id: id, run_type: type, fraction };
  }

  get runOptions() {
    return this.files.map((f) => {
      let r = this.deserializeRunId(f.pk);
      return {
        value: f,
        text: r.fraction ? `${r.run_type} ${r.fraction}` : r.run_type,
      };
    });
  }
}
