export class Environment {
  public static get development(): boolean {
    return process.env.NODE_ENV == "development";
  }
}

export const REDIRECT_URL = Environment.development
  ? "http://localhost:8080/login/callback"
  : "https://samples.sandbox.diazirine.com/login/callback";
// export const URL = Environment.development
//   ? "http://localhost:8000/api"
//   : "https://samples.sandbox.diazirine.com/api";
export const URL = "https://samples.sandbox.diazirine.com/api";

export const LAMBDA_API =
  "https://9yyko3zvxk.execute-api.us-west-2.amazonaws.com";

export type status =
  | "created"
  | "treated"
  | "fractionated"
  | "trypsinized"
  | "tmt labelled"
  | "in queue"
  | "run complete"
  | "abandoned";
export const STATUSES: status[] = [
  "created",
  "treated",
  "trypsinized",
  "tmt labelled",
  "fractionated",
  "in queue",
  "run complete",
  "abandoned",
];

export type si_unit = "pM" | "nM" | "uM" | "mM";
export const SI_UNITS: si_unit[] = ["pM", "nM", "uM", "mM"];

export interface Experiment {
  id?: number;
  description: string;
  comment: string;
  location: string | null;
  searched_file: string | null;
  instrument: number | null;
  privacy: string;
  layouts: Layout[];
  last_audit: Audit;
  folder: number | null;
  folder_name?: string;
  instrument_name?: string;
  flagged: boolean;
  run_complete: string | null;
  uploaded_to_production: boolean;
  searched_pd: boolean,
  searched_skyline: boolean,
  manually_reviewed: boolean,
}

export interface Layout {
  // experiment: number;
  channel: number;
  cell: number;
  probe: string;
  probe_conc: number;
  probe_units: si_unit;
  competitor: string | null;
  competitor_conc: number | null;
  competitor_units: si_unit | null;
  timepoint: number;
  ignore: boolean;
  enzyme: string;
}

export interface Instrument {
  id: number;
  name: string;
}

export interface Cell {
  id: number;
  name: string;
  parental: number | null;
}

export interface Folder {
  id: number;
  name: string;
  parent?: number;
}

export interface Audit {
  id?: number;
  experiment: number;
  user: string;
  comment: string;
  status: status;
  timestamp?: string;
}

export interface NewAudit {
  experiment: number;
  user: string;
  comment: string;
  status: status;
}

export interface Note {
  id: number;
  experiment: number;
  user: string;
  comment: string;
  timestamp: string;
}

export interface FileResponse {
  sk: string;
  pk: string;
  files: {
    png?: string;
    qc_report?: string;
    qc_report_filtered?: string;
    totalArea?: string;
    totalAreafilt?: string; 
    stereo_prm?: string; 
    normalized?: string;
    filtered: string;
    unfiltered_prm?: string;
    filtered_prm?: string;
    raw: string;
    summed_intensity?: string;
    // Sage TMT results that have been transformed to look like ProteomeDiscoverer results
    psuedo_pd?: string;
    // Stereo report from diazirine
    stereo_portal?: string;
    TE50_Report?: string;
    Calc_TE50?: string;
    concat?: string;
    qc_report_concat?: string;
  };
}

export interface PaginationResponse<T> {
  count: number;
  results: T[];
}
