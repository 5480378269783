
import { Vue, Component } from "vue-property-decorator";
import QcMetrics from "@/components/QcMetrics.vue";
import ExperimentMetrics from "@/components/ExperimentMetrics.vue";
import CompoundsScreened from "@/components/CompoundsScreened.vue";
import ExperimentsCompleted from "@/components/ExperimentsCompleted.vue";

@Component({
  components: {
    QcMetrics,
    ExperimentMetrics,
    CompoundsScreened,
    ExperimentsCompleted,
  },
})
export default class extends Vue {}
