
import { Component, Vue, Watch } from "vue-property-decorator";
import {
  Experiment,
  Layout,
  Audit,
  STATUSES,
  URL,
  si_unit,
  SI_UNITS,
} from "@/types";
import axios, { AxiosResponse, AxiosError } from "axios";
import { mapGetters } from "vuex";
import LayoutTable from "@/components/LayoutTable.vue";
import Upload from "@/components/Upload.vue";
import Files from "@/components/Files.vue";
import UploadToPortal from "@/components/UploadToPortal.vue";
import { SelectItem } from "@/store";
import { oktaAuth } from "@/main";

@Component({
  components: {
    LayoutTable,
    Upload,
    Files,
    UploadToPortal,
  },
  computed: {
    ...mapGetters(["get_cells", "get_instruments", "get_projects"]),
  },
})
export default class ExperimentView extends Vue {
  public get_cells!: SelectItem[];
  public get_instruments!: SelectItem[];
  public get_projects!: SelectItem[];

  public tabindex = 0;
  public audits: Audit[] = [];
  public statuses = STATUSES;

  public unlock_flag = false;
  public flag_experiment() {
    axios
      .post(`${URL}/experiment/flag/${this.experiment.id}`)
      .then((resp) => {
        this.experiment.flagged = true;
      })
      .catch((resp: Error | AxiosError) => {
        if (axios.isAxiosError(resp)) {
          this.error = `${resp.message}: ${JSON.stringify(
            resp.response?.data
          )}`;
        } else {
          this.error = resp.message;
        }
      });
  }

  // Text in layout tab
  public layout_tab = "";

  // For displaying error messages to use
  public error = "";

  // Core data model
  public experiment: Experiment = {
    id: undefined,
    instrument: null,
    last_audit: { status: "created", comment: "", user: "", experiment: 0 },
    description: "",
    location: "",
    privacy: "internal",
    searched_file: null,
    layouts: [],
    comment: "",
    folder: null,
    flagged: false,
    run_complete: null,
    uploaded_to_production: false,
    searched_pd: false,
    searched_skyline: false,
    manually_reviewed: false,
  };

  @Watch("experiment.folder")
  public try_mk_qc() {
    const project_name = this.get_projects.find(
      (x) => x.value == this.experiment.folder
    )?.text;
    if (this.experiment.id === undefined && project_name === "QC") {
      const yeast = this.get_cells.find((x) => x.text === "Yeast")?.value;
      if (yeast) {
        this.experiment.layouts = [
          {
            channel: 1,
            probe: "met6_delta",
            probe_conc: 0,
            probe_units: "uM",
            timepoint: 0,
            cell: yeast,
            competitor: null,
            competitor_conc: null,
            competitor_units: null,
            ignore: false,
            enzyme: "KR",
          },
          {
            channel: 2,
            probe: "met6_delta",
            probe_conc: 0,
            probe_units: "uM",
            timepoint: 0,
            cell: yeast,
            competitor: null,
            competitor_conc: null,
            competitor_units: null,
            ignore: false,
            enzyme: "KR",
          },
          {
            channel: 3,
            probe: "met6_delta",
            probe_conc: 0,
            probe_units: "uM",
            timepoint: 0,
            cell: yeast,
            competitor: null,
            competitor_conc: null,
            competitor_units: null,
            ignore: false,
            enzyme: "KR",
          },
          {
            channel: 4,
            probe: "his4_delta",
            probe_conc: 0,
            probe_units: "uM",
            timepoint: 0,
            cell: yeast,
            competitor: null,
            competitor_conc: null,
            competitor_units: null,
            ignore: false,
            enzyme: "KR",
          },
          {
            channel: 5,
            probe: "his4_delta",
            probe_conc: 0,
            probe_units: "uM",
            timepoint: 0,
            cell: yeast,
            competitor: null,
            competitor_conc: null,
            competitor_units: null,
            ignore: false,
            enzyme: "KR",
          },
          {
            channel: 6,
            probe: "his4_delta",
            probe_conc: 0,
            probe_units: "uM",
            timepoint: 0,
            cell: yeast,
            competitor: null,
            competitor_conc: null,
            competitor_units: null,
            ignore: false,
            enzyme: "KR",
          },
          {
            channel: 7,
            probe: "ura2_delta",
            probe_conc: 0,
            probe_units: "uM",
            timepoint: 0,
            cell: yeast,
            competitor: null,
            competitor_conc: null,
            competitor_units: null,
            ignore: false,
            enzyme: "KR",
          },
          {
            channel: 8,
            probe: "ura2_delta",
            probe_conc: 0,
            probe_units: "uM",
            timepoint: 0,
            cell: yeast,
            competitor: null,
            competitor_conc: null,
            competitor_units: null,
            ignore: false,
            enzyme: "KR",
          },
          {
            channel: 9,
            probe: "ura2_delta",
            probe_conc: 0,
            probe_units: "uM",
            timepoint: 0,
            cell: yeast,
            competitor: null,
            competitor_conc: null,
            competitor_units: null,
            ignore: false,
            enzyme: "KR",
          },
          {
            channel: 10,
            probe: "BY4741",
            probe_conc: 0,
            probe_units: "uM",
            timepoint: 0,
            cell: yeast,
            competitor: null,
            competitor_conc: null,
            competitor_units: null,
            ignore: false,
            enzyme: "KR",
          },
          {
            channel: 11,
            probe: "BY4741",
            probe_conc: 0,
            probe_units: "uM",
            timepoint: 0,
            cell: yeast,
            competitor: null,
            competitor_conc: null,
            competitor_units: null,
            ignore: false,
            enzyme: "KR",
          },
        ];
        this.experiment.description = "Yeast TMT TKO 11 plex standard";
      }
    }
  }

  public created() {
    if (!this.$route.params.pk) {
      oktaAuth.getUser().then((claims) => {
        if (claims.email) {
          this.experiment.last_audit.user = claims.email;
        }
      });
      return;
    }
    const id = Number.parseInt(this.$route.params.pk);

    Promise.all([
      axios.get<Experiment>(`${URL}/experiment/${id}/`),
      axios.get<Audit[]>(`${URL}/audit/?experiment_id=${id}`),
    ])
      .then(([expt, audit]) => {
        this.experiment = expt.data;
        this.experiment.last_audit.comment = "";
        this.experiment.last_audit.user = "";
        this.experiment.layouts.sort((a, b) => a.channel - b.channel);
        this.experiment.id = id;
        delete this.experiment.last_audit.timestamp;
        this.audits = audit.data;
        oktaAuth.getUser().then((claims) => {
          if (claims.email) {
            this.experiment.last_audit.user = claims.email;
          }
        });
      })
      .catch((resp: Error | AxiosError) => {
        if (axios.isAxiosError(resp)) {
          this.error = `${resp.message}: ${JSON.stringify(
            resp.response?.data
          )}`;
        } else {
          this.error = resp.message;
        }
      });

    let headers = new LayoutTable().fields;
    headers.pop();
    this.layout_tab = headers.join("\t") + "\n";
  }

  // check for form validity
  get can_submit(): boolean {
    let has_probes = true;
    for (let lay of this.experiment.layouts) {
      if (lay.probe.length == 0) {
        has_probes = false;
      }
    }

    return (
      this.experiment.description.length > 0 &&
      this.experiment.last_audit.user.length > 0 &&
      this.experiment.layouts.length > 0 &&
      has_probes
    );
  }

  public async dosubmit(event: Event) {
    event.preventDefault();
    let id = this.experiment.id!;
    try {
      if (this.experiment.id) {
        const a = await Promise.all([
          axios.put(`${URL}/metadata/${this.experiment.id}`, this.experiment),
        ]);
        await this.$router.go(0);
      } else {
        let xs = await axios.post<Experiment>(
          URL + "/metadata/",
          this.experiment
        );
        id = xs.data.id!;
        await this.$router.push({ name: "Home" }).then(() => {
          this.$router.push({
            name: "ExperimentView",
            params: { pk: id.toString() },
          });
        });
        // await this.$router.push({
        //   name: "ExperimentView",
        //   params: { pk: id.toString() },
        // })
      }
    } catch (err: any) {
      if (err.response) {
        // window.alert(JSON.stringify(err.response.data));
        this.error = err.response.data;
      }
    }
  }

  public async delete_expt() {
    if (this.experiment.id) {
      await axios.delete(URL + `/metadata/${this.experiment.id}`);

      await this.$router.push({
        name: "Home",
      });
    }
  }

  public parse_tab_delimited(line: string): Layout {
    let parse_unit = (s: string): si_unit | null => {
      switch (s) {
        case "mM":
          return "mM";
        case "uM":
          return "uM";
        case "nM":
          return "nM";
        case "pM":
          return "pM";
        default:
          return null;
      }
    };

    let xs = line.split("\t");
    let cell = this.get_cells.find((x) => x.text === xs[8])?.value;
    if (!cell || cell === 0) {
      throw new Error(`Invalid cell name: ${xs[8]}`);
    }

    let p_unit = parse_unit(xs[3]);
    if (!p_unit) {
      throw new Error(`Unsupported SI unit :${xs[3]}`);
    }

    return {
      // experiment: this.experiment.id!,
      channel: parseInt(xs[0]),
      probe: xs[1],
      probe_conc: parseFloat(xs[2]),
      probe_units: p_unit,
      competitor: xs[4],
      competitor_conc: parseFloat(xs[5]),
      competitor_units: parse_unit(xs[6]),
      timepoint: parseFloat(xs[7]),
      ignore: false,
      cell,
      enzyme: xs.length >= 9 ? xs[9] : "KR",
    };
  }

  public import_tab_delimited() {
    let xs = this.layout_tab.split("\n");
    xs.shift();
    try {
      let new_lay = xs
        .filter((x) => x.length > 0)
        .map(this.parse_tab_delimited);
      this.experiment.layouts = [];

      for (let lay of new_lay) {
        this.experiment.layouts.push(lay);
      }
      this.tabindex = 0;
    } catch (error) {
      this.error = `Error parsing layouts ${error}`;
    }
  }

  public layout_tab_delimited() {
    let xs = this.experiment.layouts
      .map((l) => {
        return [
          l.channel.toString(),
          l.probe.toString(),
          l.probe_conc.toString(),
          l.probe_units,
          l.competitor?.toString(),
          l.competitor_conc?.toString(),
          l.competitor_units,
          l.timepoint.toString(),
          this.get_cells.find((x) => x.value === l.cell)?.text,
          l.enzyme,
        ].join("\t");
      })
      .join("\n");

    let headers = new LayoutTable().fields;
    headers.pop();
    this.layout_tab = headers.join("\t") + "\n" + xs;
  }

  public navigateExperiment(direction: number) {
    if (this.experiment.id) {
      this.$router.push(`/experiment/${this.experiment.id + direction}`);
    }
  }
}
