
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Cell, Folder, Instrument, URL } from "@/types";
import axios from "axios";
import { mapGetters } from "vuex";
import { oktaAuth } from "./main";

@Component({
  computed: {
    ...mapGetters(["get_cells", "get_instruments"]),
  },
})
export default class App extends Vue {
  public get_cells!: Cell[];

  public error = "";
  public email: string | null = null;

  public async mounted() {
    await this.signedIn();


    try {
      // Synchronize store
      let [c, i, p] = await Promise.all([
        axios.get<Cell[]>(URL + "/cell/"),
        axios.get<Instrument[]>(URL + "/instrument/"),
        axios.get<Folder[]>(URL + "/folder/"),
      ]);
      this.$store.dispatch("load_cells", c.data);
      this.$store.dispatch("load_instruments", i.data);
      this.$store.dispatch("load_projects", p.data);

    } catch (err) {
      if (axios.isAxiosError(err)) {
        this.error = err.message;
      } else {
        this.error = `Error occurred: ${err}`;
      }
    }
  }

  public async signedIn() {
    let user = await oktaAuth.getUser();
    if (user && user.email) {
      return (this.email = user.email);
    }
    return null;
  }
}
