import Vue, { VueConstructor } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import { URL, LAMBDA_API, REDIRECT_URL } from "./types";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.min.css";
import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";
import { OktaAuth } from "@okta/okta-auth-js";

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

export const oktaAuth = new OktaAuth({
  issuer: "https://belharratx.okta.com/oauth2/default",
  clientId: "0oa1f3n3tnkHD6Ht9697",
  redirectUri: REDIRECT_URL,
  scopes: ["openid", "email", "profile", "production"],
});
oktaAuth.start();

axios.interceptors.request.use(
  (config) => {
    const token = oktaAuth.getAccessToken();
    if (
      token &&
      (config.url?.includes(URL) || config.url?.includes(LAMBDA_API))
    ) {
      if (config.method?.toUpperCase() == "PUT" || config.method?.toUpperCase() == "POST") {
        throw new axios.Cancel("DIAZIRINE 1.0 IS READ ONLY");
      }
      if (!config.headers) {
        config.headers = {};
      }
      console.log("inject auth", config.url)
      config.headers["Authorization"] = `Bearer ${token}`;
      // config.headers["Accept-Encoding"] = "gzip";
    }
    return config;
  },
  (reject) => Promise.reject(reject)
);

new Vue({
  router,
  store,
  render: (
    h: (
      arg0: VueConstructor<
        Vue<
          Record<string, any>,
          Record<string, any>,
          never,
          never,
          never,
          never,
          (
            event: string,
            ...args: any[]
          ) => Vue<
            Record<string, any>,
            Record<string, any>,
            never,
            never,
            never,
            never,
            any
          >
        >
      >
    ) => any
  ) => h(App),
}).$mount("#app");
