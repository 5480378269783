
import { Prop, Component, Vue } from "vue-property-decorator";
import axios, { AxiosError } from "axios";
import { URL, LAMBDA_API, FileResponse } from "@/types";

interface Data {
  pk: number;
  link: string;
}

@Component({})
export default class extends Vue {
  public min_expt = 843;
  public max_expt = 848;
  public error = "";
  public links: Data[] = [];

  public extract(str: string): number {
    let s = str.split("#");
    return Number(s[1]);
  }

  get items() {
    return this.links.sort((a, b) => a.pk - b.pk);
  }

  public download() {
    this.links = [];
    for (let i = this.min_expt; i <= this.max_expt; i++) {
      axios
        .get<FileResponse[]>(`${LAMBDA_API}?key=${i}`)
        .then((resp) => {
          for (let f of resp.data) {
            if (f.files.summed_intensity) {
              this.links.push({
                pk: this.extract(f.pk),
                link: f.files.summed_intensity,
              });
            }
          }
        })
        .catch((resp: any) => {
          if (axios.isAxiosError(resp)) {
            this.error = `${resp.message}: ${JSON.stringify(
              resp.response?.data
            )}`;
          } else {
            this.error = resp.message;
          }
        });
    }
  }
}
