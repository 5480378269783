
import { Vue, Component } from "vue-property-decorator";
import { oktaAuth } from "@/main";
import { CustomUserClaims } from "@okta/okta-auth-js";

@Component
export default class extends Vue {
  public token = oktaAuth.getAccessToken();
  public user: CustomUserClaims | null = null;
  // public user = oktaAuth.getUser();
  public async mounted() {
    this.user = await oktaAuth.getUser();
  }
  public async signOut() {
    // oktaAuth.signOut();
    await oktaAuth.signOut();
  }

  public copy() {
    if (this.token) navigator.clipboard.writeText(this.token);
    this.$bvToast.toast("Copied to clipboard", {
      title: "Access Token",
      autoHideDelay: 1000,
      appendToast: true,
      toaster: "b-toaster-top-right",
    });
  }
}
