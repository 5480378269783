
import { Vue, Component } from "vue-property-decorator";
import {
  Experiment,
  Layout,
  si_unit,
  SI_UNITS,
  status,
  STATUSES,
  URL,
} from "@/types";
import { oktaAuth } from "@/main";
import { mapGetters } from "vuex";
import { SelectItem } from "@/store";
import LayoutTable from "@/components/LayoutTable.vue";
import axios from "axios";

interface Field {
  expt: number;
  comment: string;
  description: string;
  instrument: string;
  project: string;
  location: string;
  audit_user: string;
  audit_comment: string;
  audit_status: string;
  channel: number;
  probe: string;
  probe_conc: number;
  probe_units: string;
  competitor: string;
  competitor_conc: number;
  competitor_units: string;
  timepoint: number;
  cell: string;
  enzyme: string;
}

@Component({
  components: {
    LayoutTable,
  },
  computed: {
    ...mapGetters(["get_cells", "get_instruments", "get_projects"]),
  },
})
export default class extends Vue {
  public get_cells!: SelectItem[];
  public get_instruments!: SelectItem[];
  public get_projects!: SelectItem[];
  public statuses = STATUSES;

  public textArea = "expt	comment	description	instrument	project	location	audit user	audit comment	audit status	channel	probe	probe_conc	probe_units	competitor	competitor_conc	competitor_units	timepoint	cell";

  public lines: Field[] = [];
  public experiments: Experiment[] = [];
  public selectedExperiment = -1;

  public email: string | null = null;
  public error = "";

  public async signedIn() {
    let user = await oktaAuth.getUser();
    if (user && user.email) {
      return (this.email = user.email);
    }
    return null;
  }

  public async mounted() {
    await this.signedIn();
  }

  public async submit() {
    // for (const e of this.experiments) {
    for (let idx = 0; idx < this.experiments.length; idx++) {
      const e = this.experiments[idx];
      await axios
        .post(URL + "/metadata/", e)
        .then((resp) => {
          this.$bvToast.toast(`Upload succeeded for experiment ${idx + 1}`, {
            title: "Success",
            autoHideDelay: 500,
            appendToast: true,
            variant: "success",
            solid: true,
            toaster: "b-toaster-top-center",
          });
        })
        .catch((err) => {
          if (err.response) {
            this.error += `Experiment ${idx + 1}: err.response.data\n`;
          }
        });
    }
  }

  private validate(field: Field): Layout {
    let cell = this.get_cells.find((x) => x.text.toLowerCase() == field.cell.toLowerCase());

    let probeUnit = SI_UNITS.indexOf(field.probe_units as si_unit);
    let competitorUnit = SI_UNITS.indexOf(field.competitor_units as si_unit);

    if (probeUnit == -1) {
      probeUnit = SI_UNITS.indexOf("uM");
    }

    if (competitorUnit == -1) {
      competitorUnit = SI_UNITS.indexOf("uM");
    }

    if (cell == undefined) {
      throw Error("Invalid cell");
    }

    let layout: Layout = {
      channel: field.channel,
      cell: cell.value,
      probe: field.probe,
      probe_conc: field.probe_conc,
      probe_units: SI_UNITS[probeUnit],
      competitor: field.competitor,
      competitor_conc: field.competitor_conc,
      competitor_units: SI_UNITS[competitorUnit],
      timepoint: field.timepoint,
      enzyme: field.enzyme,
      ignore: false,
    };
    return layout;
  }

  public doParse() {
    try {
      this.parse();
    } catch (ex) {
      this.error = `${ex}`;
    }
  }

  public parse() {
    console.log(this.get_cells);
    if (!this.email) {
      throw "not signed in!";
    }

    const lines = this.textArea.split("\n");

    let data: Field[] = [];
    for (const line of lines.slice(1).filter((x) => x.length > 0)) {
      const fields = line.split("\t");
      let field: Field = {
        expt: parseInt(fields[0]),
        comment: fields[1],
        description: fields[2],
        instrument: fields[3],
        project: fields[4],
        location: fields[5],
        audit_user: this.email,
        audit_comment: fields[7],
        audit_status: fields[8],
        channel: parseInt(fields[9]),
        probe: fields[10],
        probe_conc: parseFloat(fields[11]),
        probe_units: fields[12],
        competitor: fields[13],
        competitor_conc: parseFloat(fields[14]),
        competitor_units: fields[15],
        timepoint: parseInt(fields[16]),
        cell: fields[17],
        enzyme: fields.length >= 19 ? fields[18] : "KR",
      };

      data.push(field);
    }

    const uniqueExperiments = new Set(data.map((x) => x.expt));

    for (const expt of uniqueExperiments) {
      let expts = data.filter((x) => x.expt === expt);
      if (expts.length == 0) {
        continue;
      }

      let folder = this.get_projects.find(
        (x) =>
          x.text ==
          expts[0].project.replace("Library Screen", "Library Screening")
      );
      let instrument = this.get_instruments.find((x) =>
        x.text.includes(expts[0].instrument)
      );

      let layouts = expts.map(this.validate);

      if (!folder) {
        console.log(folder);
        throw Error(`${expts[0].project} is not a valid project`)
      }
      if (!instrument) {
        console.log(instrument);
        throw Error(`${expts[0].instrument} is not a valid instrument`)
      }

      let experiment: Experiment = {
        id: undefined,
        description: expts[0].description,
        comment: expts[0].comment,
        location: expts[0].location,
        searched_file: null,
        privacy: "internal",
        folder: folder.value,
        instrument: instrument.value,
        flagged: false,
        run_complete: null,
        last_audit: {
          status: expts[0].audit_status as status,
          comment: expts[0].audit_comment,
          user: expts[0].audit_user,
          experiment: 0,
        },
        layouts,
        uploaded_to_production: false,
        searched_pd: false,
        searched_skyline: false,
        manually_reviewed: false,
      };

      this.experiments.push(experiment);

      console.log(experiment);
    }

    if (this.experiments.length > 0) {
      this.selectedExperiment = 1;
    }
  }
}
