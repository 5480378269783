
import { Vue, Component, Watch } from "vue-property-decorator";
import axios from "axios";
import { URL, PaginationResponse } from "@/types";
import Chart from "chart.js/auto";

export interface CompoundsScreened {
  timestamp: string;
  count: number;
}

@Component
export default class extends Vue {
  public error = "";
  public loading = false;
  public data: CompoundsScreened[] = [];

  public mounted() {
    this.loading = true;
    axios
      .get<CompoundsScreened[]>(`${URL}/reports/experiments_completed`)
      .then((resp) => {
        this.data = resp.data;
        this.loading = false;
        this.mk_chart();
      })
      .catch((resp) => {
        if (axios.isAxiosError(resp)) {
          this.error = `Error fetching QC metrics: ${resp.message}`;
        } else {
          this.error = `Error fetching QC metrics: ${resp}`;
        }
      });
  }

  public mk_chart() {
    const ctx = this.$refs.ctx as HTMLCanvasElement;
    if (!ctx) {
      return;
    }

    // https://stackoverflow.com/questions/40056555/destroy-chart-js-bar-graph-to-redraw-other-graph-in-same-canvas
    let chartStatus = Chart.getChart(ctx);
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }

    const _ = new Chart(ctx, {
      type: "line",
      data: {
        labels: this.data.map((x) => new Date(x.timestamp).toDateString()),
        datasets: [
          {
            label: "Experiments completed this week",
            data: this.data.map((x) => x.count),
            backgroundColor: "green",
            borderColor: "green",
            // fill: true,
            cubicInterpolationMode: "monotone",
            tension: 0.4,
          },
        ],
      },
    });
  }
}
