
import Vue from "vue";
import { Component } from "vue-property-decorator";
import axios from "axios";
import { URL } from "@/types";

export interface FlaggedCompounds {
  probe: string;
  channel: number;
  experiment_id: number;
  ignore: boolean;
  flagged: boolean;
}

@Component
export default class Flagged extends Vue {
  public error = "";
  public loading = false;
  public data: FlaggedCompounds[] = [];
  public fields = [
    { key: "probe", label: "Probe", sortable: true },
    // { key: "project", label: "Project", sortable: true },
    // { key: "experiment_id", label: "Experiment", sortable: true },
    // { key: "channel", label: "TMT Channel", sortable: true },
    { key: "cell", label: "Cell", sortable: true },
    { key: "timestamp", label: "Last experiment run on", sortable: true}
    // { key: "ignore", label: "Channel Ignored?", sortable: true },
    // { key: "flagged", label: "Experiment Flagged?", sortable: true },
  ];

  public async mounted() {
    this.loading = true;
    axios
      .get<FlaggedCompounds[]>(`${URL}/reports/flagged_compounds`)
      .then((resp) => {
        this.data = resp.data;
        this.loading = false;
      })
      .catch((resp) => {
        if (axios.isAxiosError(resp)) {
          this.error = `Error fetching QC metrics: ${resp.message}`;
        } else {
          this.error = `Error fetching QC metrics: ${resp}`;
        }
      });
  }
}
